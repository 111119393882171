import request from "@/utils/request";

// 韩语APP题库列表
export function SubjectList(params) {
    return request({
        url: '/admin/korean_v3/subjectList',
        method: 'get',
        params
    })
}

// 韩语APP题库类型
export function SubjectType(params) {
    return request({
        url: '/admin/korean_v3/subjectType',
        method: 'get',
        params
    })
}


// 删除韩语APP题库
export function SubjectDel(data) {
    return request({
        url: '/admin/korean_v3/subjectDel',
        method: 'delete',
        data
    })
}

// 添加韩语APP题库
export function SubjectAdd(data) {
    return request({
        url: '/admin/korean_v3/subjectAdd',
        method: 'post',
        data
    })
}

// 编辑韩语APP真题试卷
export function SubjectEdit(data) {
    return request({
        url: '/admin/korean_v3/subjectEdit',
        method: 'put',
        data
    })
}

// 题库上传COS签名
export function getSubjectCosToken(params) {
    return request({
        url: '/admin/korean_v3/getSubjectCosToken',
        method: 'get',
        params
    })
}

// 韩语APP题库详情
export function SubjectDetails(params) {
    return request({
        url: '/admin/korean_v3/subjectDetails',
        method: 'get',
        params
    })
}
