<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="等级">
          <el-select
            v-model="queryForm.grade"
            placeholder="请选择等级"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in gradeList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="类型">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择类型"
            clearable
          >
            <el-option
              :label="item"
              :value="item"
              v-for="item in subjectType"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加题库</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="subjectList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="等级" prop="grade" align="center"></el-table-column>
        <el-table-column label="题目类型" prop="type" align="center"></el-table-column>
        <el-table-column label="题目名称" prop="topic" align="center">
          <template slot-scope="scope">
            <div style="max-height:200px" v-html="scope.row.topic">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row.id)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 添加题库 -->
      <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="70%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <div style="display:flex">
          <el-form-item el-form-item label="等级" label-width="100px">
          <el-select
            v-model="addForm.grade"
            placeholder="请选择等级"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in gradeList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item el-form-item label="题目类型" label-width="100px">
          <el-select
            v-model="addForm.type"
            placeholder="请选择题目类型"
            clearable
          >
            <el-option
              :label="item"
              :value="item"
              v-for="item in addFormSubjectType"
              :key="item"
            ></el-option>
          </el-select>
        </el-form-item>
        </div>
        <el-form-item el-form-item label="题目名：" label-width="100px">
           <div class="tip_nmae">
              <quill-editor
                v-model="addForm.topic"
                ref="myQuillEditor"
              >
              </quill-editor>
           </div>
        </el-form-item>

        <el-form-item v-if="addForm.type=='阅读'||addForm.type=='翻译'||addForm.type=='写作'" el-form-item label="阅读原文：" label-width="100px">
            <quill-editor
              v-model="addForm.original"
              ref="myQuillEditor"
            >
            </quill-editor>
        </el-form-item>
       
        <el-form-item v-if="addForm.type=='听力'" el-form-item label="听力音频" label-width="100px">
           <div v-if="addForm.audio_url">
                <audio
                  :src="addForm.audio_url"
                  style="cursor: pointer;outline: none;"
                  controls="controls" 
                  ref="audioPlay"
                ></audio>
          </div>
          <el-button type="primary" @click="selectFiles">{{isAdd=='add'?'上传音频':'修改音频'}}</el-button
          ><span class="file-name">{{ fileName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            hidden
            ref="files"
            @change="changeFiles"
            accept="audio/*"
          />
        </el-form-item>


        <!-- 选项tab -->
        <el-form-item  v-if="addForm.type=='听力'||addForm.type=='阅读'" label-width="100px">
          <div class="options_arr">
            <div class="options_item" @click="selectOption(index)" :class="isSelectOptions==index?'select_options':''" v-for="(item,index) in addForm.lib" :key="index">
              题目{{index+1}}
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click.stop="removeTopicLib(index)"
                size="mini"
              ></el-button>
            </div>
            <div class="addoptions"><el-button type="success" @click="addSubject">添加小题目</el-button></div>
          </div>
          
        </el-form-item>

        <template v-if="addForm.type!=='写作'&&addForm.type!=='翻译'">
          <div >
              <el-form-item v-if="addForm.type!=='单词'&&addForm.type!=='语法'" el-form-item label="小题目名：" label-width="100px">
                 <div class="tip_nmae">
                    <quill-editor
                      v-model="addForm.lib[isSelectOptions].topic_lib"
                      ref="myQuillEditor"
                    >
                    </quill-editor>
                 </div>
                 
              </el-form-item>

              <el-form-item el-form-item label="添加选项：" label-width="100px">
                  <div class="addtopic">
                    <div></div>
                    <el-button type="primary" @click="addTopicOptions(isSelectOptions)">添加题目选项</el-button>
                  </div>
                  <div class="add_box" v-for="(item,index) in addForm.lib[isSelectOptions].option" :key="index">
                      <div class="add_item">
                          {{item.key}}
                          <el-input v-model="item.value" placeholder="请输入题目选项名" style="margin:0 30px;width=100px"></el-input>
                      </div>
                      <div class="add_btn">
                        <el-button
                          v-if="item.key=='D'"
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          @click="removeOptions(index)"
                          size="small"
                        ></el-button>
                      </div>
                  </div>
              </el-form-item>

              <el-form-item el-form-item label="答案" label-width="100px">
                <el-select
                  v-model="addForm.lib[isSelectOptions].correct"
                  placeholder="请选择题目答案"
                  clearable
                >
                  <el-option
                    :label="item.value"
                    :value="item.key"
                    v-for="item in optionsArr"
                    :key="item.key"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item  el-form-item label="题目解析：" label-width="100px">
                <quill-editor
                  v-model="addForm.lib[isSelectOptions].analysis"
                  ref="myQuillEditor"
                >
                </quill-editor>
              </el-form-item>
          </div>
        </template>

        

        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

var COS = require("cos-js-sdk-v5");

import {
  SubjectList,
  SubjectType,
  SubjectDel,
  SubjectAdd,
  SubjectEdit,
  getSubjectCosToken,
  SubjectDetails,
} from "@/api/Korean/question_bank.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        grade: "",
        type: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      gradeList: [],
      subjectType: [],
      subjectList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        grade: "",
        type: "",
        topic: "",
        original: "",

        audio: "",
        lib: [
          {
            topic_lib: "",
            correct: "",
            option: [
              { key: "A", value: "" },
              { key: "B", value: "" },
              { key: "C", value: "" },
              { key: "D", value: "" },
            ],
            analysis: "",
          },
        ],
      },
      optionsArr: [
        { value: "A", key: "A" },
        { value: "B", key: "B" },
        { value: "C", key: "C" },
        { value: "D", key: "D" },
      ],
      addtitle: "",
      isAdd: "add",
      fileName: "",
      progress: 0,
      subjectTypeData: null,
      addFormSubjectType: [],
      isSelectOptions: 0,
      topicItem: {},
    };
  },
  created() {
    this.getSubjectList();
    this.getSubjectType();
  },
  watch: {
    "queryForm.grade": {
      handler(newVal) {
        for (const key in this.subjectTypeData) {
          if (newVal == key) {
            this.subjectType = this.subjectTypeData[key];
          }
        }
      },
      deep: true,
    },

    "addForm.grade": {
      handler(newVal) {
        for (const key in this.subjectTypeData) {
          if (newVal == key) {
            this.addFormSubjectType = this.subjectTypeData[key];
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 选中选项tab
    selectOption(index) {
      this.isSelectOptions = index;
    },
    // 删除小题目
    removeTopicLib(index) {
      if (this.addForm.lib.length <= 1) {
        this.$message.error("题目不能少于1个");
        return;
      }
      this.addForm.lib.splice(index, 1);
      this.isSelectOptions = this.addForm.lib.length - 1;
    },
    // 删除小题目选项
    removeOptions(index) {
      if (this.addForm.lib[this.isSelectOptions].option.length <= 3) {
        this.$message.error("选项不少于3项");
        return;
      }
      this.addForm.lib[this.isSelectOptions].option.splice(index, 1);
    },
    // 点击添加题目按钮
    addSubject() {
      this.addForm.lib.push({
        topic_lib: "",
        correct: "",
        option: [
          { key: "A", value: "" },
          { key: "B", value: "" },
          { key: "C", value: "" },
          { key: "D", value: "" },
        ],
        analysis: "",
      });
      this.isSelectOptions = this.addForm.lib.length - 1;
    },
    //   韩语APP题库列表
    getSubjectList() {
      SubjectList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.subjectList = res.data.data;
      });
    },
    // 韩语APP题库类型
    getSubjectType() {
      SubjectType().then((res) => {
        this.subjectTypeData = res.data.gradeArr;
        this.gradeList = res.data.gradeList;
      });
    },

    // 删除题库
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该题库?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      SubjectDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除题库成功");
        this.getSubjectList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加题库";
      this.addDialog = true;
    },

    // 添加题目选项
    addTopicOptions(i) {
      if (this.addForm.lib[i].option.length >= 4) {
        this.$message.error("选项不能多于4项");
        return;
      }
      this.addForm.lib[i].option.push({ key: "D", value: "" });
    },
    // 点击选择音频按钮
    selectFiles() {
      this.$refs.files.click();
    },

    // 选中音频文件
    changeFiles(e) {
      let file = this.$refs.files.files[0];
      this.fileName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getSubjectCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.audio = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },
    // 添加题库
    addNow() {
      if (this.addForm.grade == "") {
        this.$message.error("请选择等级");
        return;
      } else if (this.addForm.type == "") {
        this.$message.error("请选择题目类型");
        return;
      } else if (this.addForm.topic == "") {
        this.$message.error("请输入题目名");
        return;
      } else if (this.addForm.type == "阅读" && this.addForm.original == "") {
        this.$message.error("请输入阅读原文");
        return;
      } else if (this.addForm.type == "听力" && this.addForm.audio == "") {
        this.$message.error("请上传听力音频");
        return;
      } else if (this.addForm.analysis == "") {
        this.$message.error("请输入题目解析");
        return;
      }
      if (this.addForm.type == "翻译" || this.addForm.type == "写作") {
        this.addForm.lib = [
          {
            topic_lib: "",
            correct: "",
            option: [
              { key: "A", value: "" },
              { key: "B", value: "" },
              { key: "C", value: "" },
              { key: "D", value: "" },
            ],
            analysis: "",
          },
        ];
      }
      SubjectAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加题库成功");
        this.addDialog = false;
        this.getSubjectList();
      });
    },
    // 点击编辑图标
    editGoods(id) {
      this.isAdd = "edit";
      this.addtitle = "编辑题库";
      this.addDialog = true;
      SubjectDetails({ id }).then((res) => {
        this.addForm = res.data;
      });
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.grade == "") {
        this.$message.error("请选择等级");
        return;
      } else if (this.addForm.type == "") {
        this.$message.error("请选择题目类型");
        return;
      } else if (this.addForm.topic == "") {
        this.$message.error("请输入题目名");
        return;
      } else if (this.addForm.type == "阅读" && this.addForm.original == "") {
        this.$message.error("请输入阅读原文");
        return;
      } else if (this.addForm.type == "听力" && this.addForm.audio == "") {
        this.$message.error("请上传听力音频");
        return;
      } else if (this.addForm.analysis == "") {
        this.$message.error("请输入题目解析");
        return;
      }
      if (this.addForm.type == "翻译" || this.addForm.type == "写作") {
        this.addForm.lib = [
          {
            topic_lib: "",
            correct: "",
            option: [
              { key: "A", value: "" },
              { key: "B", value: "" },
              { key: "C", value: "" },
              { key: "D", value: "" },
            ],
            analysis: "",
          },
        ];
      }
      SubjectEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改题库成功");
        this.addDialog = false;
        this.getSubjectList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        grade: "",
        type: "",
        topic: "",
        original: "",
        audio: "",
        lib: [
          {
            topic_lib: "",
            correct: "",
            option: [
              { key: "A", value: "" },
              { key: "B", value: "" },
              { key: "C", value: "" },
              { key: "D", value: "" },
            ],
            analysis: "",
          },
        ],
      };
      this.isSelectOptions = 0;
      this.fileName = "";
      this.progress = 0;
      if (this.$refs.audioPlay !== undefined) {
        this.$refs.audioPlay.pause();
      }
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getSubjectList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getSubjectList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getSubjectList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}

.tip_nmae ::v-deep .ql-container {
  min-height: 50px;
}

.tip_nmae ::v-deep .ql-editor {
  min-height: 50px;
}

::v-deep .ql-editor {
  min-height: 300px;
}
::v-deep .ql-container {
  min-height: 300px;
}
.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  margin-bottom: 20px;
  display: flex;
  .add_item {
    flex: 1;
    display: flex;
  }
  .add_btn {
    width: 100px;
  }
}

.options_arr {
  display: flex;
  background-color: #cccbce;
  .options_item {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #fff;
    color: #323232;
    cursor: pointer;
    .el-button {
      margin-left: 5px;
    }
  }
  .select_options {
    background-color: #37a58c;
    color: #fff;
  }
  .addoptions {
    margin-left: 100px;
  }
}
</style>
 